import { createClient, Session, User } from "@supabase/supabase-js";

const SUPABASE_URL = window.location.hostname === "localhost"
  ? "https://qriewsdjojybfuhdinuu.supabase.co"
  : "https://qriewsdjojybfuhdinuu.supabase.co";
const KEY = window.location.hostname === "localhost"
  ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFyaWV3c2Rqb2p5YmZ1aGRpbnV1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDkyNDkwMjEsImV4cCI6MjAyNDgyNTAyMX0.1Fvr5Oo0RckeqonFsfPDBPLRSjlGRtQZ_WHNww3ABck"
  : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFyaWV3c2Rqb2p5YmZ1aGRpbnV1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDkyNDkwMjEsImV4cCI6MjAyNDgyNTAyMX0.1Fvr5Oo0RckeqonFsfPDBPLRSjlGRtQZ_WHNww3ABck";

export const supabase = createClient(
  SUPABASE_URL,
  KEY
);

export type { Session, User };
