import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as Constants from "src/utils/constants";
import { Session, supabase } from "src/api/supabase";
import { useDispatch } from "src/store/Store";
import { setUser } from "src/store/apps/user/User";
import Spinner from "src/views/spinner/Spinner";

const AuthGuard = ({ children, path }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const checkUser = async (
    session: Session,
    event: string | undefined = undefined,
  ) => {
    dispatch(setUser(session.user));

    const { data, error } = await supabase.from("account_profiles").select(
      "active, id",
    )
      .eq("id", session.user.id).single();
    if (
      (error || data.active === false) &&
      !matchPath(location.pathname, Constants.pages.ACCOUNT_REGISTER_STEP1) &&
      !matchPath(
        location.pathname,
        Constants.pages.ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION,
      ) &&
      !matchPath(location.pathname, Constants.pages.ACCOUNT_REGISTER_STEP2) &&
      !matchPath(location.pathname, Constants.pages.ACCOUNT_REGISTER_STEP3) &&
      !matchPath(location.pathname, Constants.pages.ACCOUNT_FORGOT_PASSWORD)
    ) {
      navigate(Constants.pages.ACCOUNT_LOGIN);
    }

    if (
      session.user.phone_confirmed_at && session.user.email_confirmed_at &&
      data?.active
    ) {
      if (event === "SIGNED_IN" && path === "auth") {
        navigate(Constants.pages.ACCOUNT_DASHBOARD);
      }
    } else if (
      session.user.phone_confirmed_at && session.user.email_confirmed_at
    ) {
      navigate(Constants.pages.ACCOUNT_REGISTER_STEP3);
    } else if (session.user.email_confirmed_at) {
      navigate(Constants.pages.ACCOUNT_REGISTER_STEP2); // SMS confirmation
    } else {
      navigate(Constants.pages.ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION);
    }
  };

  const checkUserIsAuthenticated = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error || !data.session) {
      if (path === "dashboard") {
        navigate(Constants.pages.ACCOUNT_LOGIN);
      }

      if (path === "auth") {
        if (
          !matchPath(location.pathname, Constants.pages.ACCOUNT_LOGIN) &&
          !matchPath(location.pathname, Constants.pages.CLIENT_LOGIN) &&
          !matchPath(
            location.pathname,
            Constants.pages.ACCOUNT_REGISTER_STEP1,
          ) &&
          !matchPath(location.pathname, Constants.pages.ACCOUNT_FORGOT_PASSWORD) &&
          !matchPath(location.pathname, Constants.pages.ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION)
        ) {
          navigate(Constants.pages.ACCOUNT_LOGIN);
        }
      }

      return;
    }

    checkUser(data.session!);
  };

  useEffect(() => {
    // If the user is being redirected from an email link (for isntance)
    // we can let it pass through, on certains pages
    const params = new URLSearchParams(location.search);
    if (
      matchPath(
        location.pathname,
        Constants.pages.ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION,
      ) &&
      params.get("token_hash")
    ) {
      setLoading(false);
      return;
    }

    checkUserIsAuthenticated();

    const subscription = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" || event === "USER_UPDATED") {
        if (window.localStorage.getItem("isClient") === "true") return;

        checkUser(session as Session, event);
      } else if (event == "PASSWORD_RECOVERY") {
        navigate(Constants.pages.ACCOUNT_RESET_PASSWORD);
      } else if (event === "SIGNED_OUT") {
        if (
          matchPath(
            location.pathname,
            Constants.pages.ACCOUNT_FORGOT_PASSWORD,
          ) || matchPath(location.pathname, Constants.pages.ACCOUNT_LOGIN)
        ) {
          return;
        }
        navigate(Constants.pages.ACCOUNT_LOGIN);
        dispatch(setUser(null));
      }
    });

    setLoading(false);

    return () => {
      subscription.data.subscription.unsubscribe();
    };
  }, [location.pathname]);

  if (loading) {
    return <Spinner />;
  }

  return children;
};

export default AuthGuard;
