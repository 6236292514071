import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from 'src/utils/languages/en.json';
import french from 'src/utils/languages/fr.json';

const resources = {
  en: {
    translation: english,
  },
  fr: {
    translation: french,
  },
};

declare global {
  interface Navigator {
    userLanguage: string;
  }
}

let preferedLanguage = navigator.language || navigator.userLanguage || 'fr';
preferedLanguage = preferedLanguage.startsWith('fr') ? 'fr' : 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: preferedLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
