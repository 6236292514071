import { createSlice } from "@reduxjs/toolkit";
import user1 from "src/assets/images/profile/user-1.jpg";
import { supabase, User as SupabaseUser } from "src/api/supabase";

interface StateType {
  isAuthenticated: boolean;
  user: any | null | SupabaseUser;
}

const initialState: StateType = {
  isAuthenticated: false,
  user: {
    profile_picture: user1,
  },
};

export const User = createSlice({
  name: "User",
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload;
    },
    isAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action) => {
      state.isAuthenticated = Boolean(action.payload);
      state.user = action.payload;
    },
    logout: (state) => {
      supabase.auth.signOut();
      state.isAuthenticated = false;
      state.user = {};
    },
  },
});

export const { getUser, isAuthenticated, setUser, logout } = User.actions;
export default User.reducer;
