import { createSlice } from "@reduxjs/toolkit";
import { supabase } from "src/api/supabase";

interface StateType {
  isAuthenticated: boolean;
  client: {
    logo_url: string;
    client_name: string;
    client_id: string;
    redirect_uri: string;
  }
}

const initialState: StateType = {
  isAuthenticated: false,
  client: {} as StateType["client"],
};

export const Client = createSlice({
  name: "Client",
  initialState,
  reducers: {
    getClient: (state, action) => {
      state.client = action.payload;
    },
    isAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setClient: (state, action) => {
      state.isAuthenticated = Boolean(action.payload);
      state.client = action.payload;
    },
    logout: (state) => {
      supabase.auth.signOut();
      state.isAuthenticated = false;
      state.client = {} as StateType["client"];
    },
  },
});

export const { getClient, isAuthenticated, setClient, logout } = Client.actions;
export default Client.reducer;
