// WARNING: if you modify this enum, you must also modify the enum in the backend
export enum RegisterStep {
  ACCOUNT_REGISTER_STEP1 = "account-register-1",
  ACCOUNT_REGISTER_STEP2 = "account-register-2",
  ACCOUNT_REGISTER_STEP3 = "account-register-3",
  ACCOUNT_REGISTER_STEP4 = "account-register-4",
  ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION = "account-wait-email-confirmation",
  ACCOUNT_REGISTER_WAIT_SMS_CONFIRMATION = "account-wait-sms-confirmation",
  ACCOUNT_REGISTER_COMPLETE = "account-register-complete",
}

export const pages: Record<string, string> = {
  // Register
  ACCOUNT_REGISTER_STEP1: "/auth/register",
  ACCOUNT_REGISTER_STEP2: "/auth/register/step-2",
  ACCOUNT_REGISTER_STEP3: "/auth/register/step-3",
  ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION:
    "/auth/register/wait-email-confirmation",

  // Login
  ACCOUNT_LOGIN: "/auth/login",
  CLIENT_LOGIN: "/auth/client/login",

  // Forgot password
  ACCOUNT_FORGOT_PASSWORD: "/auth/forgot-password",
  ACCOUNT_RESET_PASSWORD: "/auth/reset-password",

  // Dashboards
  ACCOUNT_DASHBOARD: "/account/dashboard",
  CLIENT_DASHBOARD: "/client/dashboard",

  ACCOUNT_APPS_CONNECTED: "/apps/app-connected",
  ACCOUNT_SETTINGS: "/account/user-profile",
  DATA_PRIVACY: "/faq/?q=privacy",
  ABOUT: "/faq/?q=about",
  TERMS_AND_CONDITIONS: "/faq/?q=terms",
  PRIVACY_POLICY: "/faq/?q=privacy",

  CLIENT_SETTINGS: "/client/profile",
  CLIENT_USERS: "/client/users",

  // SSO
  SSO_AUTHORIZE: "/sso/authorize",
  SSO_CONSENT: "/sso/consent",

  // Sponsor
  MY_SPONSORS: "/sponsors/my-sponsors",
  NEEDS_SPONSOR: "/sponsors/sponsor-requests",
};

export const pageAfterStep: Record<string, string> = {
  [RegisterStep.ACCOUNT_REGISTER_STEP1]:
    pages.ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION,
  [RegisterStep.ACCOUNT_REGISTER_WAIT_EMAIL_CONFIRMATION]:
    pages.ACCOUNT_REGISTER_STEP2,
  [RegisterStep.ACCOUNT_REGISTER_WAIT_SMS_CONFIRMATION]:
    pages.ACCOUNT_REGISTER_STEP3,
  [RegisterStep.ACCOUNT_REGISTER_STEP3]: pages.ACCOUNT_REGISTER_STEP4,
  [RegisterStep.ACCOUNT_REGISTER_COMPLETE]: pages.ACCOUNT_DASHBOARD,
};

export const sessionKeys: Record<string, string> = {
  ssoToken: "token",
};

export const API_URL: string = window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "localhost"
  ? "http://localhost:8000/api"
  : "https://neoam.community/api";

export const NEOAM_URL: string = window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "localhost"
  ? "http://127.0.0.1:5173" // That's react dev server
  : "https://neoam.community";

export const mailto_errors: Record<string, string> = {
  resend_email_confirmation: "d85e0032-64b1-483e-8c8c-9433c79de15f",
  get_sponsor_code: "f0b4cf58-a852-472d-b716-f9eba80acb29",
  chat_with_us: "e650d8c0-b7d0-4858-89a3-31a9c6aa0ddf"
};

export enum ErrorCode {
  PREVIOUS_STEP_NOT_DONE,
  JWT_NOT_FOUND,
}

// This is going to be used to calculate the progress of the registration
export const allProfileFields = [
  "not-required|full_name",
  "not-required|mail",
  "not-required|phone",
  "not-required|active",
  "birthdate",
  "sex",
  "address",
  "zipcode",
  "profile_picture",
];
