export const msalConfig = {
  auth: {
    clientId: "e1b0a40b-c31b-4658-b765-bea732a4e733",
    authority:
      "https://login.microsoftonline.com/common",
    redirectUri: window.location.origin,
    clientCapabilities: ["CP1"],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const graphConfig = {
  graphMeEndpoint: {
    uri: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  graphContactsEndpoint: {
    uri: "https://graph.microsoft.com/v1.0/me/contacts",
    scopes: ["Contacts.Read"],
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
