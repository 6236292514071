import { CssBaseline, ThemeProvider } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { useSelector } from "src/store/Store";
import { SnackbarProvider } from "notistack";
import { ThemeSettings } from "./theme/Theme";
import RTL from "./layouts/full/shared/customizer/RTL";
import ScrollToTop from "./components/shared/ScrollToTop";
import Router from "./routes/Router";
import { AppState } from "./store/Store";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./components/widgets/contacts/msalConfig";

const pca = new PublicClientApplication(msalConfig);

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state: AppState) => state.customizer);

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <SnackbarProvider>
            <ScrollToTop>{routing}</ScrollToTop>
          </SnackbarProvider>
        </RTL>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
