// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { lazy } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import AuthGuard from "src/guards/AuthGuard";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const ClientFullLayout = Loadable(lazy(() => import("../layouts/full/ClientFullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout")),
);

/* ****Pages***** */
const ModernDash = Loadable(lazy(() => import("../views/dashboard/Modern")));
const ClientDash = Loadable(lazy(() => import("../views/dashboard/ClientModern")));
const UserProfile = Loadable(
  lazy(() => import("../views/apps/user-profile/UserProfile")),
);
const AppConnected = Loadable(
  lazy(() => import("../views/apps/app-connected/AppConnected")),
);
const MySponsors = Loadable(
  lazy(() => import("../views/apps/sponsors/MySponsors")),
);
const SponsorRequests = Loadable(
  lazy(() => import("../views/apps/sponsors/SponsorRequests")),
);
const FAQ = Loadable(lazy(() => import("../views/pages/faq/Faq")));

/* ****Landing Pages***** */
const LandingPage = Loadable(
  lazy(() => import("../views/pages/landingpage/Landingpage")),
);

// authentication
const Login = Loadable(
  lazy(() => import("../views/authentication/auth1/Login")),
);
const ClientLogin = Loadable(
  lazy(() => import("../views/authentication/auth1/client/Login")),
);
const Register = Loadable(
  lazy(() => import("../views/authentication/auth1/Register")),
);
const WaitEmailConfirmation = Loadable(
  lazy(() => import("../views/authentication/auth1/wait-email-confirmation")),
);
const RegisterStep2 = Loadable(
  lazy(() => import("../views/authentication/auth1/RegisterStep2")),
);
const RegisterStep3 = Loadable(
  lazy(() => import("../views/authentication/auth1/RegisterStep3")),
);
const ForgotPassword = Loadable(
  lazy(() => import("../views/authentication/auth1/ForgotPassword")),
);
const ResetPassword = Loadable(
  lazy(() => import("../views/authentication/auth1/ResetPassword")),
);
const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Maintenance = Loadable(
  lazy(() => import("../views/authentication/Maintenance")),
);

const TermsAndConditions = Loadable(
  lazy(() => import("../views/pages/privacy-policy/TermsAndConditions")),
);

const PrivacyPolicy = Loadable(
  lazy(() => import("../views/pages/privacy-policy/PrivacyPolicy")),
);

const MyUsers = Loadable(
  lazy(() => import("../views/apps/clientUsers/MyUsers")),
);

const ClientEdit = Loadable(
  lazy(() => import("../views/apps/client-profile/ClientProfile")),
);

const NavigateWithQuery = ({ to }: { to: string }) => {
  const location = useLocation();
  return <Navigate to={{ pathname: to, search: location.search }} />;
};

const Router = [
  {
    path: "/",
    element: (
      <BlankLayout />
    ),
    children: [
      { path: "/", element: <LandingPage />, index: true },
      { path: "*", element: <NavigateWithQuery to="/404" /> },
      { path: "/404", element: <Error /> },
      { path: "/faq/", element: <FAQ /> },
      { path: "/privacy-policy/", element: <PrivacyPolicy /> },
      { path: "/terms-and-conditions/", element: <TermsAndConditions /> },
      { path: "/auth/login", element: <Login /> },
      { path: "/auth/register", element: <Register /> },
      { path: "/auth/forgot-password", element: <ForgotPassword /> },
      { path: "/auth/reset-password", element: <ResetPassword /> },

      // client
      { path: "/auth/client/login", element: <ClientLogin /> },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard path="dashboard">
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/account/dashboard", element: <ModernDash /> },
      { path: "/account/user-profile", element: <UserProfile /> },
      { path: "/apps/app-connected", element: <AppConnected /> },
      { path: "/sponsors/my-sponsors", element: <MySponsors /> },
      { path: "/sponsors/sponsor-requests", element: <SponsorRequests /> },
      { path: "/sponsors/sponsor-requests/:id", element: <SponsorRequests /> },
      { path: "*", element: <NavigateWithQuery to="/404" /> },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard path="client-dash">
        <ClientFullLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/client/dashboard", element: <ClientDash /> },
      { path: "/client/users", element: <MyUsers /> },
      { path: "/client/profile", element: <ClientEdit /> },
      { path: "*", element: <NavigateWithQuery to="/404" /> },
    ],
  },
  {
    path: "/auth",
    element: (
      <AuthGuard path="auth">
        <BlankLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/auth/register/wait-email-confirmation",
        element: <WaitEmailConfirmation />,
      },
      { path: "/auth/register/step-2", element: <RegisterStep2 /> },
      { path: "/auth/register/step-3", element: <RegisterStep3 /> },
      { path: "/auth/maintenance", element: <Maintenance /> },
      { path: "*", element: <NavigateWithQuery to="/404" /> },
    ],
  },
];

export default Router;
